import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import AboutPic7 from '../../../images/about/bizkimiz.png';
import { withTranslation } from 'react-i18next';
import base64 from 'react-native-base64';
import utf8 from 'utf8';

class MissionVision extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {
		const apiUrl2 = 'https://denizcicekcilikonya.com.tr:7072/getAboutDnz/select/';
		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error('AMssPI\'den veri çekme hatası:', error);
			});
	}

	render() {
		const { t } = this.props;

		return (
			<>
				{this.state.data2.map((item) => (
					<div className="row" key={item.id}>
						<div className="col-md-6 section-sp1 p-r30 p-xs-r15">
							<div className="heading-bx">
								<h6 className="title-ext m-b0">Deniz Çiçekçilik</h6>
								<h2 className="title-head m-b0">{t('BizKimiz')}</h2>
								<div className="sepimg"></div>
								<div dangerouslySetInnerHTML={{ __html: utf8.decode(base64.decode(item.AboutDesc)) }} />
							</div>
							<Accordion defaultActiveKey="0">
								{item.Vision && (
									<Accordion.Item eventKey="0">
										<Accordion.Header>{t('Vizyonumuz')}</Accordion.Header>
										<Accordion.Body>{item.Vision}</Accordion.Body>
									</Accordion.Item>
								)}
								{item.Mission && (
									<Accordion.Item eventKey="1">
										<Accordion.Header>{t('Misyonumuz')}</Accordion.Header>
										<Accordion.Body>{item.Mission}</Accordion.Body>
									</Accordion.Item>
								)}
								{item.ShortDesc && (
									<Accordion.Item eventKey="2">
										<Accordion.Header>{t('BizKimiz')}</Accordion.Header>
										<Accordion.Body>{item.ShortDesc}</Accordion.Body>
									</Accordion.Item>
								)}
							</Accordion>
						</div>
						<div className="col-md-6 p-lr0">
							<img src={AboutPic7} className="mvimg" alt="Deniz Çiçekçilik" />
						</div>
					</div>
				))}
			</>
		);
	}
}

export default withTranslation()(MissionVision);
