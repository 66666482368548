import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LogoWhite from "../../../images/logo-white.png"
import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
class Footer1 extends Component{
	  handleWhatsAppClick = () => {
    const whatsappURL = 'https://api.whatsapp.com/send?phone=123456789'; // Telefon numarasını değiştirin
    window.open(whatsappURL, '_blank');
  };
	constructor(props) {
		super(props);
		this.state = {

			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://denizcicekcilikonya.com.tr:7072/getContactDnz/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return(
			<>
				{/* <!-- Footer ==== --> */}
				<footer className="footer-style2">
					<div className="footer-top bt0">
						<div className="container">


						{this.state.data2.map((item) => (
							<div className="row align-items-center footer-info">
								<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg3} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">{t('Adres')}</h5>
											<p>{item.Adress}</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg1} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">{t('TelefonNumarası')}</h5>
											<p>{item.Phone} </p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 m-sm-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg2} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">{t('EmailAdres')}</h5>
											<p>{item.Mail} </p>
										</div>
									</div>
								</div>
							
							</div>

						))}
						</div>
					
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
									<p className="m-b0">Copyright © 2024 
								Stechome Software All Rights Reserved.</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-right">
									<ul className="link-inline">
										<li><Link to="/">{t('Anasayfa')}</Link></li>
										<li><Link to="/nedenbiz">{t('BizKimiz')}</Link></li>
							
										<li><Link to="/iletisim">{t('İletişim')}</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}
export default withTranslation()(Footer1); // withTranslation ile sarmalandı

