import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';
// Elements
import Slider from '../elements/slider/slider1';
import ServiceSlider1 from '../elements/services/service-slider-1';
import About from '../elements/about/about1';
import "toastr/build/toastr.min.css";

// Images

import moment from 'moment';
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
import Testimonial from '../elements/testimonial/testimonial1';


class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {


			data3: [],
			dataMuh: [],
			dataDanismanlik: [],

			dataCategory: [],

			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}


	componentDidMount() {

		const apiUrl3 = "https://denizcicekcilikonya.com.tr:7072/getBlogDnz/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


		const apiUrlDanis = "https://denizcicekcilikonya.com.tr:7072/getConsultancyCategoryDnz/select/";
		const apiUrlMuh = "https://denizcicekcilikonya.com.tr:7072/getEngineeringServicesCategoryDnz/select/";

		const apiCategory = "https://denizcicekcilikonya.com.tr:7072/getProductsCategoryDnz/select/";

		// Fetch data for consultancy

		fetch(apiCategory)
			.then((response) => response.json())
			.then((data) => {
				this.setState({ dataCategory: data });
			})
			.catch((error) => {
				console.error("Consultancy API'den veri çekme hatası:", error);
			});


		fetch(apiUrlDanis)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ dataDanismanlik: data3 });
			})
			.catch((error) => {
				console.error("Consultancy API'den veri çekme hatası:", error);
			});

		// Fetch data for engineering services
		fetch(apiUrlMuh)
			.then((response) => response.json())
			.then((dataMuh) => {
				this.setState({ dataMuh: dataMuh });
			})
			.catch((error) => {
				console.error("Engineering Services API'den veri çekme hatası:", error);
			});


	}
	render() {
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 7,
			slidesToScroll: 1,
			responsive: [

				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		const language = i18n.language

		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<Slider />

					{/* <!-- Main Slider --> */}
					<div className="content-block" id="content-area">

						{/*<div className="section-area process-area">
							<div className="container">
								<ServiceSlider100 />
							</div>
						</div>*/}


						<div className="section-area section-sp3 bg-white">
							<div className="container">
								<About />
							</div>
						</div>


						
			
					{/** 	<div className="section-area bg-white section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-4 col-md-12">
										<div className="heading-bx m-b20 text-center  text-lg-left m-md-b30">
											<h6 className="title-ext m-b0">See Our Faq</h6>
											<h2 className="title-head m-b0">Provides Best Service</h2>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Our purpose follow leading design, engineering and industrial manufacturing company specialising in mining, oil, gas and engineering.</p>
											<Link to="get-in-touch" className="btn">View Al Post</Link>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-sm-6">
										<div className="service-box text-center m-xs-b30">
											<div className="service-media">
												<img src={ServicesPic1} alt=""/>
												<div className="service-inner">
													<Link to="#" className="btn white">View All</Link>
												</div>
											</div>
											<div className="service-info">
												<h4 className="title">Fuel & Gas Management</h4>
												<p>Our purpose follow leading design, engineering and industrial manufacturing company </p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-sm-6">
										<div className="service-box text-center">
											<div className="service-media">
												<img src={ServicesPic2} alt=""/>
												<div className="service-inner">
													<Link to="#" className="btn white">View All</Link>
												</div>
											</div>
											<div className="service-info">
												<h4 className="title">Fuel & Gas Management</h4>
												<p>Our purpose follow leading design, engineering and industrial manufacturing company </p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>*/}
						
						
								<div className="section-area bg-primary section-sp2 client-appoint-box">
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="heading-bx text-white">
										

											<h6 className="title-ext m-b0">{t('İletişim')}</h6>
											<h2 className="title-head m-b0">Deniz Çiçekçilik</h2>
											<div className="ttr-separator sepimg2"></div>
										</div>
										<div className="swiper-container client-area-box">		
											<Testimonial />
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<form className="ajax-form" action="script/contact.php">
											<div className="apoint-btn-box">{t('İletişim')}</div>
											<div className="ajax-message"></div>
											<div className="heading-bx text-white">
										
												<h2 className="title-head m-b0">Çiçek Siparişi</h2>
												<div className="ttr-separator sepimg2"></div>
											</div>
											<div className="row placeani appoint-bx1">
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="name" type="text" required className="form-control valid-character" placeholder={t('İsminiz')}/>
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx"> 
															<input name="email" type="email" className="form-control" required  placeholder={t('Emailiniz')}/>
														</div>
													</div>
												</div>
												<div className="col-lg-4 col-md-4">
													<div className="form-group">
														<div className="input-group input-bx">
															<input name="phone" type="text" required className="form-control int-value" placeholder={t('Telefon')}/>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<div className="form-group">
														<div className="input-group input-bx">
															<textarea name="message" rows="4" className="form-control" placeholder={t('Mesaj')}required ></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12 col-md-12">
													<button name="submit" type="submit" value="Submit" className="btn button-md">{t('Gönder')}</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
						
						
					



					<div className="section-area bg-white  blog-area">
							<div className="container">
								
								<div className="section-area  bg-white">


						
     
          {this.state.dataCategory.map((category, index) => (
            <div className="col-lg-12 col-md-12" key={index}>
              <h2 className="category-title">{category.Products_Category_Title}</h2>
              <div className="products-list">
                <div className="row flex-nowrap overflow-auto">
                  {category.Products.length > 0 ? (
                    category.Products.map((product, i) => (
                      <div className="col-lg-4 col-md-6 mb-4" key={i}>
                        <div className="product-card">
                          <img
                            src={'https://megasubdomain.stechomeyazilim.info/' + product.Products_MainImage}
                            alt={product.Products_Title}
                            className="img-fluid custom-img"
                          />
                          <h4 className="product-title">{product.Products_Title}</h4>
                          <p className="product-desc">{product.Products_ShortDesc}</p>
                          <Link
                            to={{
                              pathname: `/hizmetdetayAra_/${product.ID}`,
                            }}
							state= {{ _item : product, _category:category }}
                            className="btn btn-primary"
                          >
                           Ürünü İncele
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Uygun Ürün Bulunmamaktadır!</p>
                  )}
                </div>
              </div>
            </div>
          ))}
     
    
								</div>



							</div>
						</div>

				
			

					</div>

				</div>


				<div className="section-area bg-white section-sp1 blog-area">
					<div className="container">
						<ServiceSlider1 />
					</div>
				</div>

				<Footer1 />
			</>
		);
	}
}

export default withTranslation()(Index); // withTranslation ile sarmalandı
