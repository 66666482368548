import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

import BlogSidebar from '../elements/blog/blog-sidebar';
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş

class BlogClassicSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
	
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {
		

			const apiUrl3 = "https://denizcicekcilikonya.com.tr:7072/getBlogDnz/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


	}
	
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Blog</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
										<li>Blog</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-md-12 col-lg-8 col-xl-8 m-md-b50">
										<div className="row">



										{this.state.data3.map((item) => (
											<div className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-delay="0.2s">
												<div className="recent-news">
													<div className="action-box">
														<img  className="custom-image2" src={this.state.imageUrl+item.Blog_Image} alt=""/>
													</div>
													<div className="info-bx">
														
														<h4 className="post-title"><Link to={{ pathname: `/blogdetayAra/${item.ID}`}} state={{ _item: item }} >{item.Title}</Link></h4>
														<p>{item.ShortDesc}</p>
														<div className="post-extra">
															<Link to={{ pathname: `/blogdetayAra/${item.ID}`}} state={{ _item: item }} className="btn-link black radius-xl">{t('Devam')}</Link>
														</div>
													</div>
												</div>
											</div>
										
										))}
										
										</div>
									</div>
									<div className="col-md-12 col-lg-4 col-xl-4 sticky-top wow fadeInUp" data-wow-delay="0.4s">
										<BlogSidebar />
									</div>
								</div>
							</div>
						</div>		
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}


export default withTranslation()(BlogClassicSidebar); // withTranslation ile sarmalandı
