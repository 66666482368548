import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Image1 from "../../../images/about/bizkimiz.png"
import Image2 from "../../../images/about/bizkimiz2.png"
import { withTranslation } from 'react-i18next'; 
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import utf8 from "utf8";
class About1 extends Component{
	constructor(props) {
		super(props);
		this.state = {
	
			data2: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {

		const apiUrl2 = "https://denizcicekcilikonya.com.tr:7072/getAboutDnz/select/";

		fetch(apiUrl2)
			.then((response) => response.json())
			.then((data2) => {
				this.setState({ data2 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});

	}
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return(
			<>
				<div className="row">
					<div className="col-md-6">
						<div className="about-img-box3">
							<div className="">
								<img src={Image1} alt=""/>
							</div>
							<div className="img2">
								<img src={Image2} alt=""/>
							</div>
						</div>
					</div>


					{this.state.data2.map((item) => (
					<div className="col-md-6">
						<div className="heading-bx m-b20">
							<h6 className="title-ext m-b0">Deniz Çiçekçilik</h6>
							<h2 className="title-head m-b0">{t('BizKimiz')}</h2>
					
							{item.AboutDesc != null ? <ReactHtml html={utf8.decode((base64.decode((item.AboutDesc))))} />: null}
						</div>
						<Link to="nedenbiz" className="btn">{t('BizKimiz')}</Link>
					</div>
					))}
				</div>
			</>
		);
	}
}
export default withTranslation()(About1); // withTranslation ile sarmalandı


