import React, { Component } from 'react';
import { Link ,useLocation} from 'react-router-dom';


import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';


import Banner1 from '../../images/banner/banner1.jpg';
import ReactHtml from 'raw-html-react';
import { withTranslation } from 'react-i18next';
import utf8 from "utf8";

import base64 from 'react-native-base64';


class SolutionAgriculturalEngineering extends Component {



  constructor(props) {
    super(props);
    this.state = {
      data3: [],
      data4: [],
      imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
    };
    this.handleRedirect = this.handleRedirect.bind(this);

  }

  handleRedirect(item3,_item) {
    console.log("item3123",item3)
    console.log("_item123",_item)
    const { _item2 } = this.props;

    const newPath = {
      pathname: '/danismanlikhizmetleri_',
      state: {
        _item: item3,
        _item2:_item2
      }
    };
    this.props.history.push(newPath);
  }

  componentDidMount() {

    const apiUrl4 = "https://denizcicekcilikonya.com.tr:7072/getConsultancyCategoryDnz/select/";

    fetch(apiUrl4)
      .then((response) => response.json())
      .then((data4) => {
        this.setState({ data4 });
      })
      .catch((error) => {
        console.error("Engineering Services API'den veri çekme hatası:", error);
      })
      
  }



  render() {

    
    const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir
    const { _item,_product ,_allCategory} = this.props;

    console.log("sdmflş",_product)
    return (
      <>
        <Header1 />

        <div className="page-content bg-white">
          <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: `url(${Banner1})` }}>
            <div className="container">
              <div className="page-banner-entry">
              <h1 className="text-white">{t('DH')}</h1>
                <div className="breadcrumb-row">
                  <ul className="list-inline">
                    <li><Link to="/"><i className="fa fa-home"></i> {t('Anasayfa')}</Link></li>
                    <li>{t('DH')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="content-block" id="content-area">
            <div className="section-area section-sp2 bg-fix bg-white">
              <div className="container about-video">
                <div className="row">
                  <div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                    <aside className="sticky-top">
                      <div className="widget">
                        <ul className="service-list">


                        {_allCategory.map((item3) => (
                            <li><button onClick={() => this.handleRedirect(item3)}>
                                
                                <span>{item3.Products_Category_Title}</span>

                              <i className="fa fa-angle-right"></i> </button></li>
                          ))}


                         
                            <li><button onClick={() => this.handleRedirect(_item)}>
                            
                             
                                <span>{_item.Products_Category_Title}</span>

                              <i className="fa fa-angle-right"></i> </button></li>
                     

                        </ul>
                      </div>


                    </aside>
                  </div>

                  {_product.map((item3) => (
                              <div className="col-lg-8 col-md-7 service-textarea wow fadeInUp portfolio-content" data-wow-delay="0.4s">
                              <div className="heading-bx text-left m-b20">
                               
                                  <h3 className="title-head m-b0">{item3.Products_Title}</h3>
                             
                      
                              </div>
                              <div className="row ">
          
          
          
                                <div className="col-md-12 m-b15">
                                  <img
                                    src={this.state.imageUrl + item3.Products_MainImage}
                                    className="radius-sm imghight"
                                    alt=""
                                    style={{
                                      height: '300px', // Sabit yükseklik değeri (istediğiniz değeri girebilirsiniz)
                                      width: '100%',
                                      objectFit: 'cover'
                                    }}
                                  />
          
                                </div>
          
          
          
          
                              </div>
                             
                              <Link
                            to={{
                              pathname: `/hizmetdetayAra_/${item3.ID}`,
                            }}
							state= {{ _item : item3, _category:_item }}
                            className="btn btn-primary"
                          >
                           Ürünü İncele
                          </Link>

                            </div>
                          ))}

               
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer1 />
      </>
    );
  }
}

export default withTranslation()(SolutionAgriculturalEngineering); // withTranslation ile sarmalandı
