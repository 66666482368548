import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';

import RecentPic1 from "../../../images/blog/grid/blog.png"
import RecentPic2 from "../../../images/blog/recent-blog/pic2.jpg"
import RecentPic3 from "../../../images/blog/recent-blog/pic3.jpg"
import GalleryPic1 from "../../../images/blog/grid/blog.png"
import GalleryPic2 from "../../../images/gallery/pic2.jpg"
import GalleryPic3 from "../../../images/gallery/pic3.jpg"
import GalleryPic4 from "../../../images/gallery/pic4.jpg"
import GalleryPic5 from "../../../images/gallery/pic5.jpg"
import GalleryPic6 from "../../../images/gallery/pic6.jpg"
import moment from 'moment';
import { withTranslation } from 'react-i18next'; // withTranslation eklenmiş
const content = [
	{
		thumb: GalleryPic1,
	},
	{
		thumb: GalleryPic1,
	},
	{
		thumb: GalleryPic1,
	},
	{
		thumb: GalleryPic1,
	},
	{
		thumb: GalleryPic1,
	},
	{
		thumb: GalleryPic1,
	}
]

const options = {
	settings: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#fe5a0e",
		slideAnimationType: 'slide',
	},
	buttons: {
		backgroundColor: "#fe5a0e",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	}
};

function GalleryImg(){
	return(
		<>
			<SimpleReactLightbox>
				<SRLWrapper options={options}>
					<ul className="magnific-image">
						{content.map((item) => (
							<li><img src={item.thumb} alt=""/></li>
						))}
					</ul>
				</SRLWrapper>
			</SimpleReactLightbox>	
		</>
	);
}

class BlogSidebar extends Component{
	constructor(props) {
		super(props);
		this.state = {
	
			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		};
	}

	componentDidMount() {
		

			const apiUrl3 = "https://denizcicekcilikonya.com.tr:7072/getBlogDnz/select/";

		fetch(apiUrl3)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
			})
			.catch((error) => {
				console.error("API'den veri çekme hatası:", error);
			});


	}
	render(){
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		return(
			<>
				<aside className="side-bar sticky-top aside-bx">
					
					<div className="widget recent-posts-entry">
						<h6 className="widget-title">{t('SonBlog')}</h6>
						<div className="widget-post-bx">


						{this.state.data3.slice(0,3).map((item) => (
							<div className="widget-post clearfix">
								<div className="ttr-post-media"> <img src={this.state.imageUrl+item.Blog_Image} width="200" height="143" alt=""/> </div>
								<div className="ttr-post-info">
									<div className="ttr-post-header">
										<h6 className="post-title"><Link to={{ pathname: `/blogdetayAra/${item.ID}`}} state={{ _item: item }} >{item.Title}</Link></h6>
									</div>
									<ul className="media-post">
										<li><Link to="#">{moment(item.Created_DateTime).format('YYYY-MM-DD')}</Link></li>
									</ul>
								</div>
							</div>
						))
						}


						</div>
					</div>
				
					<div className="widget widget_tag_cloud">
						<h6 className="widget-title">{t('Etiket')}</h6>
						<div className="tagcloud"> 

						{this.state.data3.map((item) => (
					
							<Link to="#">{item.Title}</Link> 
							))
						}

						</div>
					</div>
				</aside>
			</>
		);
	}
}
export default withTranslation()(BlogSidebar); // withTranslation ile sarmalandı


