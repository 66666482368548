import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import BlogSidebar from '../elements/blog/blog-sidebar';


import moment from 'moment';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64'
import { withTranslation } from 'react-i18next';
import utf8 from "utf8";

class BlogDetailsSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {

			data3: [],
			imageUrl: 'https://megasubdomain.stechomeyazilim.info/',
		}
	}



	componentDidMount() {
		const { _item } = this.props;
		const apiUrl4 = "https://denizcicekcilikonya.com.tr:7072/getPacketsDnzFilter/select/" + _item.ID;

		// Fetch data for consultancy


		// Fetch data for engineering services
		fetch(apiUrl4)
			.then((response) => response.json())
			.then((data3) => {
				this.setState({ data3 });
				console.log("data4", data3);
			})
			.catch((error) => {
				console.error("Engineering Services API'den veri çekme hatası:", error);
			});


	}

	render() {
		const { t, i18n } = this.props; // withTranslation kullanıldığı için this.props üzerinden erişilir

		const { _item } = this.props;

		const language = i18n.language


		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner page-banner-sm">
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-black">Hizmet Detay</h1>
								<div className="breadcrumb-row text-black">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Hizmet Detay</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-white">
							<div className="container">
								<div className="row">
									<div className="col-lg-4 col-md-5 m-b30 wow fadeInUp" data-wow-delay="0.2s">
										<aside className="sticky-top">
											<div className="widget">
												<ul className="service-list">


													{this.state.data3.map((item5) => (

														item5.PacketsMember.map((packet) => (

															<li><Link to={{ pathname: '/hizmetdetay_',  state: { _itemim: _item.ID,_item: item5.ID,_item2: item5.Title,_itemm:item5} }} >
																{language == "en" ?
																	<span>{packet.TitleEng}</span> :
																	<span>{packet.Title}</span>}

																<i className="fa fa-angle-right"></i> </Link></li>
														))))}

												</ul>
											</div>


										</aside>
									</div>
									<div className="col-md-8 col-lg-8 col-xl-8 m-md-b50 wow fadeInUp" data-wow-delay="0.2s">
										{/* <!-- blog start --> */}
										<div className="blog-lg blog-single">
											<div className="action-box blog-lg">
												<img src={this.state.imageUrl + _item.PacketsMemberImage} alt="" />
											</div>
											<div className="info-bx">

												<div className="ttr-post-title">
													{language == "en" ?
														<h4 className="post-title">{_item.TitleEng}</h4> :
														<h4 className="post-title">{_item.Title}</h4>
													}
												</div>
												<div className="ttr-post-text">



													<p>
														{language == "en" ? _item.DescriptionEng :
															_item.Description}

													</p>

												</div>
												<div className="ttr-divider bg-gray"><i className="icon-dot c-square"></i></div>

											</div>
										</div>

										{/* <!-- blog END --> */}
									</div>

								</div>
							</div>
						</div>

					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default withTranslation()(BlogDetailsSidebar); // withTranslation ile sarmalandı
